@import "@vmf/components/src/assets/style/_index.scss";
































































































































.out-in-warehouse-echarts {
    width: 100%;
}

.out-in-warehouse {
    background-color: #fff;
}
