@import "@vmf/components/src/assets/style/_index.scss";





































































































































.question-box {
    border: 1px solid $color-border;
    margin: $margin 0;

    > .header {
        padding: $padding;
        background-color: mix($color-info, #fff, 10%);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        > .label {
            flex: 1;

            > span {
                color: $color-danger;
                margin: 0 $margin-small;
            }
        }

        > .extra {
            flex: 0 0 auto;

            > i {
                font-size: 120%;
            }
        }
    }

    > .body {
        padding: $padding;

        > .options {
            > dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                > dt {
                    flex: 0 0 auto;
                    width: 2em;
                    height: 2em;
                    line-height: 2em;
                    text-align: center;
                    border-radius: 100vw;
                    margin-right: $margin-small;
                    font-weight: bold;

                    &:after {
                        content: ':';
                    }

                    &.active {
                        background-color: $color-success;
                        color: #fff;

                        &:after {
                            content: '';
                        }
                    }
                }

                > dd {
                    flex: 1;
                    padding: $padding-small 0;
                }
            }
        }
    }

    > .footer {
        padding: $padding;
        border-top: 1px solid $color-border;

        > .info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: $margin;

            > dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                > dt {
                    flex: 0 0 auto;
                    border-radius: 100vw;
                    margin-right: $margin-small;
                }

                > dd {
                    flex: 1;
                    font-weight: bold;
                }
            }
        }
    }
}
