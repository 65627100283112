@import "@vmf/components/src/assets/style/_index.scss";



















































































































































.exam-result-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $color-primary;
    color: #fff;

    > dl {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: $padding;

        > dt {
            flex: 0 0 auto;
            margin-right: $margin-small;
        }
    }
}

.exam-result-label {
    padding: $padding;
    background-color: mix($color-warning, #fff, 20%);
    margin: $margin 0;
}
